import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  // baseUrl: 'http://localhost:5000'
  baseUrl: process.env.REACT_APP_API_URL
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

export const api = createApi({
  reducerPath: 'api',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Songs'],
  endpoints: () => ({}),
})
